body {
    font-family: 'Quicksand', sans-serif;
}

h1, h2, h3, h4 {
    color: $secondary !important;
}

h5, h6 {
    color: $primary;
}

a, p, li {
    color: $text;
    font-size: 16px;
    line-height: 28px;
}
.subscribe{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    a{
        background: $primary;
        border: none;
        border-radius: 0;
        color: #fff;
        text-shadow: none;
        transition: background-color .3s ease;
        min-width: 150px;
        height: 55px;
        padding: 10px 15px;
        display: flex;
        justify-content: center;
        align-items: center;
        text-transform: uppercase;
        margin: 20px 0px;
        &:hover{
            opacity: 0.8;
        }
    }
}